<template>
  <slot />
  <common-loading
    v-if="isLoading"
  />
  <common-modal-error
    v-if="isError"
    :error-title="errorTitle"
    :error-message="errorMessage"
    :error-code="errorCode"
    role="guard"
    @close="($event: boolean) => closeErrorModal($event)"
  >
  </common-modal-error>
</template>
<script setup lang="ts">
  import http from '~/http-common'
  import { provide, ref, watch } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { logout } from '~/libs/logout'
  import { updateIsErrorKey, updateErrorTitleKey, updateErrorMessageKey, updateErrorCodeKey } from '~/const/updateErrorKey'
  import { updateIsLoadingKey } from '~/const/updateIsLoadingKey'

  const isLoading = ref(false)
  const updateIsLoading = (b: boolean) => isLoading.value = b
  provide(updateIsLoadingKey, updateIsLoading)

  const isError = ref(false)
  const updateIsError = (b: boolean) => isError.value = b
  provide(updateIsErrorKey, updateIsError)

  const errorTitle = ref('')
  const updateErrorTitle = (s: string) => errorTitle.value = s
  provide(updateErrorTitleKey, updateErrorTitle)

  const errorMessage = ref('')
  const updateErrorMessage = (s: string) => errorMessage.value = s
  provide(updateErrorMessageKey, updateErrorMessage)

  const errorCode = ref('')
  const updateErrorCode = (s: string) => errorCode.value = s
  provide(updateErrorCodeKey, updateErrorCode)

  const closeErrorModal = async (isSystemError: boolean) => {
    updateIsError?.(false)
    updateErrorTitle?.('')
    updateErrorMessage?.('')
    updateErrorCode?.('')
    if (isSystemError) {
      await logout(router)
    }
  }

  const route = useRoute()
  const router = useRouter()
  document.title = route?.meta?.title as string
  watch(route, (to) => {
    document.title = to?.meta?.title as string
  })
  router.onError((error) => {
    const errorString: string = error.toString()
    const regex = /dynamically imported module/
    if (regex.test(errorString)) {
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    }
  })

  http.interceptors.response.use(async (response) => {
    if (response.data) {
      if ((response.data.code == 500 && response.data.msg == 'システムエラー　管理者に連絡してください')
        || (response.data.code == -1 && response.data.msg == '生徒情報データがありません。')) {
        // 想定外エラー（セッション切れ等）
        // 強制ログアウト
        await logout(router)
        return Promise.reject()
      }
    }
    // 成功時の処理
    return response
  },
  (error: any) => {
    return Promise.reject(error) // エラーをそのまま返却
  })
</script>
